import React, { useState } from 'react'
import logonavbar from '../../assets/logonavbar.png'
import downloadicon from '../../assets/downloadicon.svg'
import './navbar.css'
import { Link } from 'react-router-dom';



function Navbar() {
   
    const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
  <>
   <nav  className="navbar fixed-top navbar-expand-lg">
                <div className="container-fluid">
                    <div><img className='logoo' src={logonavbar} alt='logo' /></div>
                    <button
        className="navbar-toggler custom-toggler"
        type="button"
        aria-label="Toggle navigation"
        onClick={toggleMenu}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>

                   
                    <div className={`navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0" style={{textDecoration:"none"}}>

                            <li className="nav-item">
                                <Link className="nav-link active" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active " to="/features">Features</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="/about">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="/contact">Contact</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link active" to="/contact">Delete Profile</Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link active    " to="/privacypolicy">Privacy policy</Link>
                            </li> */}
                            
                           
                            
                        </ul>
                        <div className="download"  style={{background:"#7F01FC",borderRadius:"8px",width:"175px",
                            height:"40px" ,color:"white"}} >
                                <a style={{textDecoration:"none"}} href="https://play.google.com/store/apps/details?id=com.wolooka.testwin.testwin">
                                  <button>Download Now</button>
                                <img src={downloadicon} alt="" />
                               
                               </a>
                            </div>
                      
                        
                    </div>
                </div>
            </nav>

 

  </>
  )
}

export default Navbar