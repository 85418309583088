import React from 'react'
import homeimg from '../../assets/homeimg.png'
import bgM from '../../assets/bgM.png'
import './home.css'
import Features from '../features/Features'
import Testimonials from '../testimonial/Testimonials'
import Faqs from '../Faqs/Faqs'
import { Link } from 'react-router-dom';

function Home() {
    
  return (
    <>
    <div className="hero-section">
    <div className="hero-left">
        <div className="text">
            <span>TESTWIN</span>
            <p className='para1'>Learn, Play & Earn</p>
            <p className='para2'>Revolutionize learning with our AI-powered edtech app Testwin, providing personalized and engaging educational experiences for all learners.</p>
        </div>
        <div className='bt-cont'>
        <div className='btn btns'>
        <Link to="/features"><button>Explore Features</button></Link>


            
            
        </div>
        <div  className=' btn downloadApp'>
            <a href="https://play.google.com/store/apps/details?id=com.wolooka.testwin.testwin"><button>Download Now</button></a>
        </div>
        </div>
       
    </div>
    <div className="hero-right">
       
        <img className='mockup1-img' src={homeimg} alt="Home" />
       
    </div>
    <div className="hero-right1">
       
       <img className='mockup1-img' src={bgM} alt="Homebg" />
      
   </div>
   
    </div>
    <Features/>
    <Testimonials/>
    <Faqs/>
    </>
  )
}

export default Home