import React from 'react'
import './about.css'
import aboutus1 from '../../assets/aboutus1.svg'
import socialicon1 from '../../assets/socialicon1.svg'
import socialicon2 from '../../assets/socialicon2.svg'
import socialicon3 from '../../assets/socialicon3.svg'
import socialicon4 from '../../assets/socialicon4.svg'
import line from '../../assets/line.svg'
import ourteam1 from '../../assets/ourteam1.svg'
import ourteam2 from '../../assets/ourteam2.svg'
import ourteam3 from '../../assets/ourteam3.svg'



function About() {
  return (
    <div style={{ marginTop: "70px" }}>
    <div className='d-flex flex-wrap justify-content-around align-items-center' style={{ background: "#F7F5FB", padding: "80px 90px" }}>
        <div>

        </div>
        <div>
            <p style={{ fontSize: "40px", fontWeight: "600", color: "black" }}>Testwin</p>
            <p style={{ fontWeight: "500", fontSize: "16px", color: "#959595" }}>TestWin is a comprehensive online platform that offers a range<br />
                of test preparation services for students aspiring to pursue<br />
                higher education in various fields.<br /><br />

                Our platform provides an interactive learning experience to<br />
                help students prepare for competitive exams, entrance tests,<br />
                and other assessments.<br /><br />

                Our team of experienced educators and industry professionals<br />
                has curated a collection of study materials, practice tests,<br />
                and mock exams to provide students with the necessary skills and<br />
                knowledge to excel in their exams. We cover a wide range of exams,<br />
                including but not limited to JEE, NEET, CAT, GMAT, GRE, SAT,<br />
                and more.</p>
        </div>
    </div>
    <div>
        <div className='d-flex flex-wrap justify-content-around align-items-center' style={{ margin: "40px", borderRadius: "20px" }}>
            <div>
                <p style={{ fontSize: "16px", fontWeight: "500", color: "#959595" }}>Who we are</p>
                <p style={{ fontSize: "40px", fontWeight: "500" }}>About Us</p>
                <p style={{ fontSize: "16px", fontWeight: "500", color: "#959595", lineHeight: "200%" }}>At TestWin, we believe that every student has the potential to succeed and<br />
                    achieve their academic goals. That's why we have designed our platform to be<br />
                    user-friendly and accessible to students from all backgrounds. Our platform provides<br />
                    personalized learning paths, customized study plans, and progress tracking tools to<br />
                    help students stay on track and monitor their progress.</p>
            </div>
            <div>
                <img src={aboutus1} alt='aboutus' />
            </div>
        </div>
    </div>
    <div style={{ background: "#7F01FC" }}>
        <div className=' d-flex flex-column align-items-center justify-content-around'>
            <h3 style={{ fontSize: "40px", fontWeight: "700", color: "white", marginTop: "20px" }}>Our Operating Team</h3>
            <p style={{ fontWeight: "500", color: "white", marginTop: "15px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra nunc ante velit vitae. Est<br /> tellus vitae, nullam lobortis enim. Faucibus amet etiam tincidunt rhoncus, ullamcorper velit.<br /> Ullamcorper risus tempor, ac nunc libero urna, feugiat.
            </p>
        </div>
        <div className='mb-5 d-flex flex-wrap align-items-center justify-content-center'>
            <div className="d-flex m-2 flex-column align-items-center justify-content-center" style={{ background: "white", height: "440px", padding: "30px", borderRadius: "10px", boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)" }}>
                <img src={ourteam1} alt='ourteam'/>
                <h6 style={{ marginTop: "10px" }}>Aakash Singh</h6>
                <p>CEO</p>
                <p>B.Tech LLB (IPR),LLM(Corporate<br /> Law) IITKharagpur, National<br /> Law University,Raipur</p>
                <div className='d-flex flex-row'>
                    <img src={socialicon1}  alt="socialicon"/>
                    <img src={line}  alt="socialicon" />
                    <img src={socialicon2}  alt="socialicon" />
                    <img src={line}  alt="socialicon" />
                    <img src={socialicon3}   alt="socialicon"/>
                    <img src={line}  alt="socialicon"/>
                    <img src={socialicon4}  alt="socialicon" />
                </div>
            </div>
            {/* <div className="d-flex m-2 flex-column align-items-center justify-content-center" style={{ background: "white", padding: "30px", borderRadius: "10px", boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)" }}>
                <img src={ourteam2}  alt="ourteam"/>
                <h6 style={{ marginTop: "10px" }}>Aman Sindal</h6>
                <p>CDO</p>
                <p>Google Certified UX Designer,<br />Mech. Eng, Ganpat University </p>
                <div className='d-flex flex-row'>
                    <img src={socialicon1} alt="socialicon" />
                    <img src={line} alt="socialicon" />
                    <img src={socialicon2}  alt="socialicon"/>
                    <img src={line} />
                    <img src={socialicon3} alt="socialicon" />
                    <img src={line} alt="socialicon" />
                    <img src={socialicon4} alt="socialicon" />
                </div>
            </div> */}
            {/* <div className="d-flex m-2 flex-column align-items-center justify-content-center" style={{ background: "white", padding: "30px", borderRadius: "10px", boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)" }}>
                <img src={ourteam3} />
                <h6 style={{ marginTop: "10px" }}>Dharmendra Singh Jaat</h6>
                <p>CTO</p>
                <p>.Tech M.Tech, PhD (Data Science)<br /> IITKharagpur</p>
                <div className='d-flex flex-row'>
                    <img src={socialicon1} alt="socialicon"/>
                    <img src={line} alt="socialicon"/>
                    <img src={socialicon2} alt="socialicon"/>
                    <img src={line} />
                    <img src={socialicon3} alt="socialicon"/>
                    <img src={line} />
                    <img src={socialicon4} alt="socialicon"/>
                </div>
            </div> */}
        </div>
    </div>
   
</div>
  )
}

export default About