
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import Features from './components/features/Features';
import Footer from './components/footer/Footer';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import TermsAndCondition from './components/TermsAndCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteProfile from './components/DeleteProfile/deleteProfile';
import ScrollToTop from './ScrollToTop';


function App() {
  return (
    <>
  <BrowserRouter>
      <ScrollToTop/>
      <Navbar/>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/features" element={<Features/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/termsandcondition" element={<TermsAndCondition/>} />
      <Route path="/privacy" element={<PrivacyPolicy/>} />
      <Route path="/deleteProfile" element={<DeleteProfile/>} />





      

      </Routes>
      <Footer/>
   
      </BrowserRouter>
    </>
  );
}

export default App;
