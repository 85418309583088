import React, { useEffect, useRef, useState } from "react";
import "./features.css";
import star from "../../assets/star.png";
import eva from "../../assets/eva.mp4";
import star2 from "../../assets/star2.png";
import group1 from "../../assets/Group1.svg";
import group2 from "../../assets/Group2.svg";
import group3 from "../../assets/Group3.svg";
import group5 from "../../assets/Group5.svg";
import livefeed from "../../assets/livefeedpng.png";
import chatroom from "../../assets/chatroompng.png";
import groups from "../../assets/grouppng.png";
import study from "../../assets/studycirclepng.png";
import study2 from "../../assets/studycircle2png.png";
import challenge from "../../assets/mychallengespng.png";
import profile from "../../assets/profileinfopng.png";
import livefeed1 from "../../assets/livefeed1.jpeg";
import livefeed2 from "../../assets/Livefeed2.svg";
import gps1 from "../../assets/Livefeed2.svg";
import gps2 from "../../assets/Livefeed2.svg";
import studycir1 from "../../assets/Livefeed2.svg";
import studycir2 from "../../assets/Livefeed2.svg";



function Features() {
  const videoRef = useRef(null);

  useEffect(() => {
    // Ensure video is loaded before attempting to autoplay
    const video = videoRef.current;
    video.addEventListener("loadeddata", () => {
      video.play().catch((error) => {
        // Autoplay was prevented by the browser, possibly due to user settings
        console.error("Autoplay was prevented:", error);
      });
    });

    return () => {
      // Cleanup: remove event listener
      // video.removeEventListener('loadeddata');
    };
  }, []);

 
   

  return (
    <div className="feature-section">
      <div className="feature-tag">
        <span>FEATURES</span>
        <p>Our special features to make learning more intuitive</p>
      </div>
      <div className="Eva">
        <div className="eva-gif">
          {/* <img src={bgeva} alt="" /> */}
          <video
            className="eva-video"
            ref={videoRef}
            width="188"
            height="320"
            autoPlay
            loop
            muted
          >
            <source src={eva} type="video/mp4" />
          </video>
        </div>
        <div className="learn-section">
          <div className="text-section">
            <p>
              {" "}
              <img className="starimg1" src={star} alt="" />
              Learn with your{" "}
              <span style={{ color: "#7F01FC" }}>
                personalized <br />{" "}
                <span className="AI" style={{ float: "" }}>
                  A.I teacher Eva.
                </span>
              </span>{" "}
            </p>
            <p>
              Ace your exams with our AI voice <br />{" "}
              <span className="AI" style={{ float: "" }}>
                chatbot Eva.{" "}
              </span>{" "}
              <img className="starimg2" src={star2} alt="" />
            </p>
          </div>
        </div>
      </div>

      <div className="play-section">
        <div className="text-section2">
          <img className="starimg3" src={star} alt="" />
          <p>
            {" "}
            Free <span style={{ color: "#7F01FC" }}> Video lectures.</span>{" "}
          </p>
          <br />
          <br />
          <p>
            Ace your exams with our free video <br />{" "}
            <span className="lecture" style={{}}>
              {" "}
              lectures.{" "}
            </span>
            <img className="starimg4" src={star2} alt="" />
          </p>
        </div>
        <div style={{ marginLeft: "0px" }} className="mockupimg2">
          <img src={group1} alt="" />
        </div>
      </div>

      <div className="section">
        <div className="mockupimg3">
          <img src={group2} alt="" />
        </div>
        <div className="text-section3">
          <img className="starimg5" src={star} alt="" />
          <div>
            <p style={{ float: "right" }}>
              {" "}
              Generate tests through
              <br />
              <span style={{ color: "#7F01FC" }}>
                {" "}
                <span className="AI-eva">A.I teacher Eva. </span>
              </span>
            </p>{" "}
            <br />
            <p style={{ display: "inline-block" }}>
              {" "}
              <br />
              Now you can generate your course topic tests <br />
              <span className="AI-eva">with our A.I teacher EVA. </span>{" "}
            </p>
          </div>
          <img className="starimg6" src={star2} alt="" />
        </div>
      </div>

      <div className="play-section">
        <div className="text-section4">
          <img className="starimg7" src={star} alt="" />
          <p>
            Monitor your preparation <br /> progress through{" "}
            <span style={{ color: "#7F01FC" }}> Testwin.</span>{" "}
          </p>
          <br />
          <p>
            {" "}
            <br /> Monitor your progress with previous test <br />{" "}
            <span className="analysis" style={{ float: "" }}>
              {" "}
              analysis.{" "}
            </span>
            <img className="starimg8" src={star2} alt="" />
          </p>
        </div>
        <div style={{ marginLeft: "0px" }} className="mockupimg2">
          <img src={group3} alt="" />
        </div>
      </div>

      <div className="section">
        <div className="mockupimg3">
          <img src={group5} alt="" />
        </div>
        <div className="text-section5">
          <img className="starimg9" src={star} alt="" />
          <div>
            <p style={{ float: "right" }}>
              Course topic PDF
              <br />
              <span style={{ color: "#7F01FC" }}>
                {" "}
                <span className="generation"> generation. </span>
              </span>
            </p>{" "}
            <br />
            <p style={{ display: "inline-block" }}>
              {" "}
              <br />
              Generate your course topic pdf through our A.I <br />
              <span className="generation">teacher EVA. </span>{" "}
            </p>
          </div>
          <img className="starimg10" src={star2} alt="" />
        </div>
      </div>

      <div className="analysis-section">
        <div className="text-section6">
          <img className="starimg11" src={star} alt="" />
          <p>
            Alone we can do so little,
            <span style={{ color: "#7F01FC" }}>
              {" "}
              <br /> together we can do so much.
            </span>{" "}
          </p>
          <br />
          <p>
            {" "}
            Learning alone can be boring and stagnant. <br /> Join groups or
            create your own study circle <br />{" "}
            <span className="span-aper">
              {" "}
              and learn with aspirants all around.{" "}
            </span>
            <img className="starimg12" src={star2} alt="" />
          </p>
        </div>
        <div className="image-grid">
          <div className="image1">
            <img style={{width:"250px",height:"448px"}} src={livefeed} alt="livefeed" />
          </div>
          <div className="image2">
            <img style={{width:"250px",height:"351px"}} src={groups} alt="groups" />
          </div>
          <div className="image3">
            <img style={{width:"250px",height:"306px"}} src={study} alt="study" />
          </div>
          <div className="image4">
            <img style={{width:"250px",height:"367px"}} src={study2} alt="studycircle" />
          </div>
          <div className="image5">
            <img style={{width:"250px",height:"470px"}} src={challenge} alt="challenge" />
          </div>
          <div className="image6">
            <img style={{width:"250px",height:"218px"}} src={chatroom} alt="chatroom" />
          </div>
          <div className="image7">
            <img style={{width:"250px",height:"269px"}} src={profile} alt="profile" />
          </div>
        </div>
        <div className="feature-mobile">
          <div className="live-feed">
            <p>Live Feed</p>
            <div className="image-container">
            <img style={{width:"185px",height:"365px"}} src={livefeed1} alt="livefeed" />
            <img style={{width:"185px",height:"365px"}} src={livefeed2} alt="livefeed" />
            </div>
           
          </div>

          <div className="live-feed">
            <p>Groups</p>
            <div className="image-container live">
            <img style={{width:"185px",height:"365px" ,marginLeft:"20px"}} src={gps1} alt="livefeed" />
            <img style={{width:"185px",height:"365px",marginLeft:"20px"}} src={gps2} alt="livefeed" />
            </div>
           
          </div>

          <div className="live-feed">
            <p>Study Circle</p>
            <div className="image-container">
            <img style={{width:"185px",height:"365px"}} src={studycir1} alt="studycir1" />
            <img style={{width:"185px",height:"365px"}} src={studycir2} alt="studycir1" />
            </div>
           
          </div>

        </div>
      </div>
    </div>
  );
}

export default Features;
