import React from 'react'
import './testimonial.css'
import testiimg from '../../assets/Testiimg.svg'
import testimobile from '../../assets/testimobile.svg'

function Testimonials() {
  return (
    <>
    <div className='testimonial-container'>
        <p>WHAT OUR WINNERS SAY ABOUT US</p>
        <div className="testi-img">
            <img src={testiimg} alt="" />
        </div>
        <div className="testimonial-mobile">
          <img src={testimobile} alt="" />
      
    </div>

    </div>
    
    </>
  )
}

export default Testimonials