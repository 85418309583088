// Footer.js

import React from 'react';
// import { FaApple, FaGooglePlay } from 'react-icons/fa';
// import { RiArrowRightSLine } from 'react-icons/ri';
import {Link} from 'react-router-dom';

import './footer.css';
import logofooter from '../../assets/logofooter.svg'
import istore from '../../assets/istore.png'

import playstore from '../../assets/playstore.png'
import facebook from '../../assets/facebook.png'
import instagram from '../../assets/InstagramLogo.png'
import linkedin from '../../assets/LinkedinLogo.png'
import twitter from '../../assets/Vector.png'
// import linkdn from '../../assets/linkdn.png'




const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo">
          {/* Your company logo */}
          <img src={logofooter} alt="Company Logo" />
        </div>
        {/* <p>Download Now</p> */}
        <div className="footer-app-links">
          {/* App store and Google Play links */}
          
          <a href="#">
            <img src={istore} alt="" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.wolooka.testwin.testwin">
          <img src={playstore} alt="" />
          </a>
        </div>
      </div>
      <hr />
      <div className="footer-middle">
        <div className="footer-section company">
          <h4>Company</h4>
          <ul style={{listStyleType:"none"}}>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="/home">Home</Link></li>
            
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="/about">About Us </Link></li>
           
          </ul>
          {/* Company content */}
        </div>
        <div className="footer-section resources">
          <h4>Resources</h4>
          <ul style={{listStyleType:"none"}}>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="/home">Help Centre</Link></li>
            
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6",
       }} to="/about">Contact Support</Link></li>
           
          </ul>

          {/* Resources content */}
        </div>
        <h4 className='follow' style={{fontSize:""}}>Follow us</h4>
        <div className="footer-section social-media">
          
          {/* Follow Us content */}
          <div className='social-mediaa'>
          <ul style={{listStyleType:"none"}}>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="https://www.linkedin.com/company/testwin/"><img style={{marginLeft:"-10px"}}  src={linkedin} alt="" />  Linkedin</Link></li>
            
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="https://twitter.com/TestWinPlay?t=DoblcHmqJ07QveqX0n7Uhg&s=09"><img style={{marginLeft:"-15px"}} src={twitter} alt="" />   Twitter</Link></li>
            </ul>
            </div>
            <div className='facebook'>
            <ul style={{listStyleType:"none"}}>
            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="https://www.facebook.com/Testwin.learn"><img style={{marginLeft:"-2px"}}  src={facebook} alt="" />  Facebook</Link></li>

            <li><Link style={{textDecoration:"none",fontSize:"14px",fontWeight:"500",color:"#F6F6F6"}} to="https://www.instagram.com/testwin.play"><img  style={{marginLeft:"2px"}}  src={instagram} alt="" /> Instagram</Link></li>
            </ul>
            </div>

        </div>
        <div className="footer-section contact">
          <h4>Contact Us</h4>
          {/* Address content */}
          <p style={{ fontSize: "14px", fontWeight: "700",color:"white"}}>
              Bhamashah techno hub (BTH) <br />
              3rd Floor Sansthan Path,<br />
              Jhalana Gram, Malviya Nagar,<br />
              Jaipur , Rajasthan<br />
              302017<br /><br />
            </p>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <div className="footer-terms">
          <Link style={{textDecoration:"none"}} to="/termsandcondition">Terms and Conditions</Link>
     
          <Link style={{textDecoration:"none"}} to="/privacy">Privacy Policy</Link>
        </div>
        <div className="footer-copyright">
          <span>&copy; {new Date().getFullYear()} by Testwin Co.</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
