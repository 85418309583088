import React from 'react'

function PrivacyPolicy() {
  return (
    <div style={{ marginTop: "70px",  }}>
    <div style={{margin: "20px",padding:"30px",background:"#F7F5FB",borderRadius:"50px"}}>
    <h2>Privacy Policy</h2>
    <p style={{ marginTop: "40px" }}>Wolooka Technologies Private Limited operates the portal in India, which is an artificial intelligence-driven gamified learning and assessment platform that rewards the students for their exam preparation on their application Testwin a Flagship product of Wolooka Technologies Private Limited, referred to herein as “TestWin” or “we” or “us” or “our”.
      Any person utilizing the mobile application ("User" or "you" or "your") or any of its features including participation in the various contests (including tests being conducted on the mobile application) shall be bound by this Privacy Policy.<br />
      TestWin respects the privacy of its Users and is committed to protecting it in all respects. Intending to offer an enriching and holistic internet experience to its Users, TestWin offers a vast repository of Services. Kindly take time to read the 'About Us' section to know more about TestWin. Most of the Services are offered for free but you may need registration to participate in TestWin's online tests. The information about the User is collected by TestWin as (i) information supplied by Users and (ii) information automatically tracked during the User's navigation on TestWin.<br />
      Before you submit any information to the Portal, please read this Privacy Policy for an explanation of how we will treat your personal information. By using any part of the Portal, you consent to the collection, use, disclosure, and transfer of your personal information for the purposes outlined in this Privacy Policy and to the collection, processing, and maintenance of this information. If you do not agree to this Privacy Policy, please do not use the Portal. Your use of any part of the Portal indicates your acceptance of this Privacy Policy and the collection, use, and disclosure of your personal information following this Privacy Policy. While you have the option not to provide us with certain information, withdraw your consent to collect certain information, request temporary suspension of the collection of information, or request deletion of information collected, kindly note that in such an event you may not be able to take full advantage of the entire scope of features and services offered to you and we reserve the right not to provide you with our services.
    </p>
    <h3>Purpose and Usage:</h3>
    <p>To avail of certain Services on the mobile application, Users would be required to provide certain information for the registration process namely:<br />
      1. Username<br />
      2. Password<br />
      3. Email address<br />
      4. Date of birth<br />
      In the course of providing you with access to the Services, and to provide you access the features offered through the Portal and verify your identity, and secure your account details, you may permit us to capture, record your device information, operating system information, network information, location information. You may also be required to furnish additional information, including your Permanent Account Number.
      In certain instances, we may also collect Sensitive Personal Information (“SPI”) from you on the Portal. SPI means such personal information which consists of information relating to your physical, physiological, and mental health condition; medical records and history; biometric information, sexual orientation, and financial information, such as information regarding the payment instrument/modes used by you to make such payments, which may include cardholder name, credit/debit card number (in encrypted form) with the expiration date, banking details, wallet details, etc. This information is presented to you at the time of making a payment to enable you to complete your payment expeditiously.
      Except for any financial information that you choose to provide while making payment for any Services on the Portal, TestWin does not collect any other SPI in the course of providing the Services. Any SPI collected by TestWin shall not be disclosed to any third party without your express consent, save as otherwise set out in this Privacy Policy or as provided in a separate written agreement between TestWin and you or as required by law. It is clarified that this condition shall not apply to publicly available information, including SPI, with you on the Portal.
      In the course of providing the Services, Users may invite other existing Users or other users ("Invited Users") to participate in any of the Services by providing the email address or Facebook username of such users. TestWin may thereafter use this information to contact the Invited User and invite such user to register with TestWin (if such Invited User is not an existing User) and participate in the Platform activities about which such person was invited by the User. The participation of the Invited User in any of the Platform Activities including but not limited to Tests, Lectures, Offers, and Games shall be subject to the terms of this Privacy Policy and the Terms and Conditions for the use of the Portal. The User hereby represents that the Invited Users have consented and agreed to such disclosure to and use of their email address and Facebook username by TestWin.
      All required information is specific and based on the kind of Test/Coyrses the User wishes to participate in or access, and will be utilized to provide services, including but not limited to the Services requested by the User. The information supplied by the Users enables us to improve the Services and provide you with the most user-friendly game experience.
      TestWin may also share such information with affiliates and third parties in limited circumstances, including to provide services requested by the User, comply with legal processes, prevent fraud or imminent harm, and ensure the security of our network and services.
    </p>
    <h3>Disclosure/Sharing:</h3>
    <p>TestWin may also share information as provided by you and data concerning usage of the Services and participation in the Games with third-party service providers engaged by TestWin, for data analytics or other similar purposes, storage, improving the services, and helping TestWin serve you better.<br />
      Where we propose to use your personal information (that is, information that may be used to identify the User and that is not otherwise publicly available) for any other uses we will ensure that we notify you first. You will also be allowed to withhold or withdraw your consent for your use other than as listed above.<br />
      By using the mobile application, you hereby expressly agree and grant consent to the collection, use, and storage of this information by TestWin. TestWin reserves the right to share, disclose and transfer information collected hereunder with its affiliates. In the event TestWin sells or transfers all or a portion of its business assets, consumer information may be one of the business assets that are shared, disclosed, or transferred as part of the transaction. You hereby expressly grant consent and permission to TestWin for disclosure and transfer of information to such third parties. TestWin may share information as provided by you and data concerning usage of the Services and participation in the Game with its commercial partners to facilitate user engagement, for marketing and promotional purposes, and other related purposes. Further, TestWin reserves the right to disclose personal information as obligated by law, in response to duly authorized legal processes, and governmental requests, and as necessary to protect the rights and interests of TestWin.
    </p>
    <h3>Use of Cookies:</h3>
    <p>To improve the effectiveness and usability of the Portal for our Users, we use "cookies", or similar electronic tools to collect information to assign each visitor a unique random number as a User Identification (User ID) to understand the User's interests using the identified computer. Unless the User voluntarily identifies himself/herself (e.g., through registration), TestWin has no way of knowing who the User is, even if we assign a cookie to the User's computer. The only personal information a cookie can contain is information supplied by the User. A cookie cannot read data off the User's hard drive. TestWin’s advertisers may also assign their cookies to the User's browser (if the user clicks on their ad banners), a process that TestWin does not control.
      TestWin's web servers automatically collect limited information about the User's computer's connection to the Internet, including the User's IP address, when the User visits the Portal. (User's IP address is a number that lets computers attached to the Internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally. TestWin uses this information to deliver its web pages to Users upon request, tailor its Portal to the interests of its users, measure traffic within the Portal, and let advertisers know the geographic locations from where TestWin's visitors come.
    </p>
    <h3>Links:</h3>
    <p>TestWin also includes links to other websites. Such websites are governed by their respective privacy policies, which are beyond TestWin's control. Once the User leaves TestWin's servers (the User can tell where he/she is by checking the URL in the location bar on the User's browser), the use of any information provided by the User is governed by the privacy policy of the operator of the site which the User is visiting. That policy may differ from TestWin's own. If the user can't find the privacy policy of any of these sites via a link from the site's homepage, the User may contact the site directly for more information. TestWin is not responsible for the privacy practices or the content of such websites.
    </p>
    <h3>Security Procedures:</h3>
    <p>All information gathered on TestWin is securely stored within the TestWin - controlled database. The database is stored on servers secured behind a firewall; access to such servers is password-protected and strictly limited based on a need-to-know basis. However, we understand that as effective as our security measures are, no security system is impenetrable. Thus, we cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. Further, any information you include in a posting to the discussion areas will be available to anyone with Internet access. By using the Portal, you understand and agree that your information may be used in or transferred to countries other than India.<br />
      TestWin also believes that the internet is an ever-evolving medium. We may periodically review from time to time and change our privacy policy to incorporate such future changes as may be considered appropriate, without any notice to you. Our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be. Any changes to our privacy policy will be posted on this page, so you are always aware of what information we collect, how we use it, how we store it, and under what circumstances we disclose it.
    </p>
    <h3>Advertising:</h3>
    <p>When TestWin presents information to its online advertisers to help them understand our audience and confirm the value of advertising on the Portal it is usually in the form of aggregated statistics on traffic to various pages within our site. When you register with TestWin, we contact you from time to time about updating your content to provide features that we believe may benefit you.<br />
      Several deceptive emails, websites, blogs, etc. claiming to be from or associated with TestWin may or are circulating on the Internet. These emails, websites, blogs, etc. often include our logo, photos, links, content, or other information. Some emails, websites, blogs, etc. call the user requesting the user to provide a login name, password, etc. or inform the user that the user has won a prize/ gift, or provide a method to commit an illegal or unauthorized act or deed or request detailed personal information or a payment of some kind. The sources and contents of these emails, websites, blogs, etc., and accompanying materials are in no way associated with TestWin. For your protection, we strongly recommend not responding to such emails or using these websites, blogs, etc. We may use the information provided by you to TestWin, including your email address or phone number, to contact you about the services availed by you or to inform you of our updated Services if any.
    </p>
    <h3>Conditions of Use:</h3>
    <p>TESTWIN DOES NOT WARRANT THAT THIS MOBILE APPLICATION, ITS SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. TESTWIN WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PORTAL, INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL TESTWIN BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT<br /> information described in this Privacy Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations, or legitimate business purposes to retain your personal information and the retention of your personal information is not required for the establishment, exercise or defense of any legal claim.
    </p>
    <h3>User Account and Data Deletion:</h3>
    <p>Users are entitled to request TestWin delete their User accounts and their personal information by sending an email with their written request to support@testwin.in<br />
      We will do our best to respond promptly and in any event within one month of the following:<br />
      Our receipt of your written request;<br />
      Our receipt of any further information we may ask you to provide to enable us to comply with your request, whichever is later.<br />
      As an alternative to account deletion, by writing to support@testwin.in, you also have the option to request either:<br />
      (i) the suspension of your account, after which you will not be able to play paid contests on your User account but will continue to have access to other parts of the Portal; <br />
      (ii) temporary deactivation of your account, where you will no longer be able to log into the Portal but which allows you to request reactivation of your account with all your account data.<br />
      If you proceed with the permanent deactivation of your account, you will lose access to the Portal and the Services, including any User data and the personal information associated with your account. Users may request account restoration within six (6) months from the date of notification of account deletion by TestWin by writing to support@testwin.in.<br />
      When you request deletion of your data, we follow a deletion process that ensures that your data is safely and completely removed from our servers or retained only in anonymized form. We try to ensure that our services protect information from accidental or malicious deletion. Because of this, there may be delays between when you request the deletion and when copies are deleted from our active and backup systems.
    </p>
    <h3>Applicable Law and Jurisdiction:</h3>
    <p>By visiting this mobile application, you agree that the laws of the Republic of India without regard to its conflict of laws principles, govern this Privacy Policy, and any dispute arising in respect hereof shall be subject to and governed by the dispute resolution process set out in the Terms and Conditions.
    </p>
    <h3>Updating Information:</h3>
    <p>You will promptly notify TestWin if there are any changes, updates, or modifications to your information. Further, you may also review, update or modify your information and user preferences by logging into your Profile page on the Portal.
    </p>
    <h3>Advertisements on Platform:</h3>
    <p>We use third-party advertising companies to serve ads when you visit our Platform. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites to provide advertisements about goods and services of interest to you. You have an option to opt out from tracking personalized advertising using the “Opt out of Ads Personalization” settings using your device’s settings application. TestWin will have no access to your GAID once you select this feature.

    </p>
    </div>
  </div>
  )
}

export default PrivacyPolicy