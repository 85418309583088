import React, { useState } from 'react'
import { BsPlusSquare, BsDashSquare } from 'react-icons/bs';
import './faqs.css'

function Faqs() {
    const faqs = [
        { question: "What is EVA?", answer: "EVA stands for Educational Virtual Assistant. It is an AI-powered tutor designed to assist individuals in their learning journey, particularly in preparing for exams such as UPSC, SSC, and others." },
        { question: "How does EVA work?", answer:" EVA works by leveraging artificial intelligence algorithms to create personalized study plans, generate syllabi tailored to individual needs, and provide answers to questions related to various subjects and exam formats. It analyzes user inputs, learning patterns, and course requirements to offer optimized study schedules and relevant educational content." },
        { question: "How can I learn with EVA?", answer: "Learning with EVA is simple and intuitive. Users can interact with EVA through a user-friendly interface, either via a web platform or a dedicated mobile application. Upon registration, users can specify their exam goals, preferred subjects, and study preferences. EVA then generates a customized study plan, offers resources such as textbooks, video lectures, and practice tests, and provides timely feedback and assistance throughout the learning process." },
        { question: "Can EVA help me prepare for specific exams like UPSC or SSC?", answer: " Yes, EVA is specifically designed to assist individuals in preparing for various competitive exams, including UPSC, SSC, and others. It offers comprehensive study materials, practice questions, and exam strategies tailored to the requirements of each exam." },
        { question: "Is EVA capable of providing answers to questions related to my study material?", answer: "Absolutely! EVA's AI algorithms are trained on vast datasets encompassing diverse subject areas. It can quickly retrieve accurate answers to questions related to your study material, thereby facilitating comprehension and reinforcing learning." },
        { question: "How does EVA ensure that I stay on track with my study schedule?", answer: "EVA employs advanced tracking and monitoring features to help users stay on track with their study schedules. It sends reminders for upcoming study sessions, monitors progress through regular assessments and quizzes, and adjusts the study plan dynamically based on individual performance and feedback." },
        { question: "Is EVA available 24/7?", answer: "Yes, EVA is available round the clock to assist users with their learning needs. Whether you prefer to study during the day or at night, EVA is there to support you every step of the way." },
        { question: "How can I get started with EVA?", answer: "Getting started with EVA is easy! Simply visit our website or download the Testwin mobile app from the App Store or Google Play Store. Follow the registration process, customize your study preferences, and embark on your learning journey with EVA by your side." },
        
      ];
    
      const [activeIndex, setActiveIndex] = useState(null);
    
      const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };
  return (
    <div className="faqs-container">
    <p>FAQ`s</p>
    {faqs.map((faq, index) => (
      <div key={index} className="faq-container">
        <div className={`faq-question ${activeIndex === index ? 'clicked' : ''}`} onClick={() => toggleAccordion(index)}>
          {faq.question}
          <span className="icon">
            {activeIndex === index ? <BsDashSquare size={20} /> : <BsPlusSquare size={20} />}
          </span>
        </div>
        {activeIndex === index && (
          <div className="faq-answer">
            {faq.answer}
          </div>
        )}
      </div>
    ))}
  </div>
  )
}

export default Faqs