import React, { useState } from "react";
import "../DeleteProfile/contact.css";
import ClipLoader from "react-spinners/ClipLoader";

function DeleteProfile() {
  const [showOne, setShowOne] = useState(true);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contactNumber: "",
    otp: "",
    checkbox1: false,
    checkbox2: false,
  });

  const handleVerifyOtp = async () => {
    setLoading(true);
    if (formData.otp.length >= 4) {
      try {
        const otpNumber = parseInt(formData.otp, 10);
        const response = await fetch("https://payments.umla.in/api/v1/auth/verifyOtp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            contactNumber: formData.contactNumber,
            otp: otpNumber,
            deviceId: "hviwevnegvwebvwebbe",
          }),
        });

        if (response.ok) {
          setShowTwo(false);
          setShowThree(true);
        } else {
          alert("Error verifying OTP. Please try again later.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please fill in the OTP to proceed.");
      setLoading(false);
    }
  };

  const handleDeleteProfile = async () => {
    setLoading(true);
    if (formData.checkbox1) {
      try {
        const response = await fetch("https://payment.umla.in/api/v1/course/deleteUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            contactNumber: formData.contactNumber,
          }),
        });

        if (response.ok) {
          setShowTwo(false);
          setShowThree(true);
        } else {
          alert("Error deleting profile. Please try again later.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please check the checkbox to proceed.");
      setLoading(false);
    }
  };

  const handleSendOtp = async () => {
    setLoading(true);
    if (formData.contactNumber.length >= 13) {
      try {
        const response = await fetch("https://payments.umla.in/api/v1/auth/sendOtp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumber: formData.contactNumber,
          }),
        });

        if (response.ok) {
          setShowOne(false);
          setShowTwo(true);
        } else {
          alert("Error sending OTP. Please try again later.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please check your contact number to proceed.");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="contact-us">
      {loading && (
        <div className="loading-overlay">
          <ClipLoader color="#ffffff" loading={loading} size={150} />
        </div>
      )}
      <div className="heading flex justify-center font-bold text-3xl text-white border border-black p-4">
        Delete Profile
      </div>
      
      <div className="info">
        {showOne && (
          <div className="form-container">
            <input
              style={{ padding: "15px" }}
              type="text"
              placeholder="+91 - Enter contact number"
              value={formData.contactNumber}
              onChange={(e) =>
                setFormData({ ...formData, contactNumber: formData.contactNumber.length === 0 ? "+91" : e.target.value })
              }
            />
            <button className="Btn" onClick={handleSendOtp}>
              Send OTP
            </button>
          </div>
        )}
        {showTwo && (
          <div className="form-container">
            <input
              style={{ padding: "15px" }}
              type="text"
              placeholder="Enter OTP"
              value={formData.otp}
              onChange={(e) => setFormData({ ...formData, otp: e.target.value })}
              disabled={!formData.contactNumber}
            />
            <button className="Btn" onClick={handleVerifyOtp} disabled={!formData.otp}>
              Verify
            </button>
          </div>
        )}
        {showThree && (
          <div className="form-container">
            <label>
              <input
                style={{ marginRight: "10px" }}
                type="checkbox"
                value={formData.checkbox1}
                onChange={(e) => setFormData({ ...formData, checkbox1: e.target.checked })}
                disabled={!formData.otp}
              />
              Your Profile from the Testwin App will be deleted !!!
            </label>
            <button className="Btn" onClick={handleDeleteProfile}>
              Delete Profile
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteProfile;
